//常用方法

// 浏览器小数计算精确问题
export function accMul(arg1,arg2){  
	var m=0,s1=arg1.toString(),
		s2=arg2.toString();  
	try{
		m+=s1.split(".")[1].length}catch(e){}  
	try{
		m+=s2.split(".")[1].length}catch(e){}  
	return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}

//验证
//手机(大陆)
export function isPhone(phonevalue){
   var phoneReg = /^1[3-578]\d{9}$/;
   if(phoneReg.test(phonevalue)){
	   return true;
   }else{
	   return false;
   }
}
//手机(香港)
export function isHKPhone(phonevalue){
	var reg = /^\d{8}$/;
	if(reg.test(phonevalue)){
		return true;
	}else{
		return false;
	}
}
//邮箱
export function isEmail(emailValue){
	var mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
	if(mailReg.test(emailValue)){
		return true;
	}else{
		return false;
	}
}
//密码验证
export function pwdReg(pwdValue){
	//包含两种及以上字符且不可为汉字
	// var pwdReg=/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,}$/;
	var pwdReg=/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)/;
	if(pwdReg.test(pwdValue) && !hanReg(pwdValue)){
		return true;
	}else{
		return false;
	}
}
//禁止输入中文汉字
export function hanReg(value){
	// var chReg = /\p{Unified_Ideograph}/ug;  //npm会报错
	var chReg = /[\u4e00-\u9fa5]/g;
	if(value.search(chReg) >= 0){
		return true;
	}else{
		return false;
	}
}


//切换指定class(有则删，无则添) 参数：1.切换class的dom元素  2.class名称
export function toggleClass(el, className){
	var allClass = el.className;//元素所拥有所有class
	if(allClass){//存在class时
		if(allClass == className){//只有一个切换的class时
			el.className = '';
		}else{
			if(allClass.indexOf(className) == -1){
				el.className += ' ' + className;
			}else{
				el.className = allClass.replace(' '+className, '');
			}
		}
	}else{//没有class直接添加
		el.className = className;
	}
}


export function timesStr (date) {
	let d = new Date(date * 1000);    //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	let day = d.getDate();
	let time = year + '/' + month + '/' + day
	
	return time
}

export function setCookie(c_name,value,expire) {
    var date=new Date()
    date.setSeconds(date.getSeconds()+expire)
    document.cookie=c_name+ "="+escape(value)+"; expires="+date.toGMTString() + ";domain=.medinbd.com;"
    //console.log(document.cookie)
}
 
export function getCookie(c_name){
    if (document.cookie.length>0){
        let c_start=document.cookie.indexOf(c_name + "=")
        if (c_start!=-1){ 
            c_start=c_start + c_name.length+1 
            let c_end=document.cookie.indexOf(";",c_start)
            if (c_end==-1) c_end=document.cookie.length
                return unescape(document.cookie.substring(c_start,c_end))
            } 
        }
    return ""
}
 
export function delCookie(c_name){
    setCookie(c_name, "", -1)
}

//当前所属设备
export function device(){
	return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent)?'mobile':'pc' ;
}