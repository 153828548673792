import Vue from "vue";
import VueRouter from "vue-router";

import agent from './agent.js'

Vue.use(VueRouter);

const routes = [
  // home
  {// 首页
    path: "/",
    name: "home",
	meta: {
		title: '首頁'
	},
    component: () => import("@/components/home")
  }, 
  {// 套餐购买
	  path: "/home/buyCombo",
	  name: 'buyCombo',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '健康大數據精準套餐購買'
	  },
	  component: () => import("@/components/home/buyCombo")
  }, 
  {// 疫境求真套餐购买
	  path: "/home/buyYjqz",
	  name: 'buyYjqz',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '疫境求真大數據套餐'
	  },
	  component: () => import("@/components/home/buyYjqz")
  }, 
  {// 疫境求真套餐购买
	  path: "/home/buyGoods",
	  name: 'buyGoods',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: 'AI健康套餐'
	  },
	  component: () => import("@/components/home/buyGoods")
  },
  {// 案例展示
	  path: "/home/caseShow/:id",
	  name: 'caseShow',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '案例展示'
	  },
	  component: () => import("@/components/home/caseShow")
  }, 
  
  //study 米因研究
  {// 列表
  	  path: "/study",
  	  name: 'study',
  	  meta:{
  		  needLogin:false,//设置此路由是否需要登录状态
  		  title: '米因研究'
  	  },
  	  component: () => import("@/components/study")
  },
  {// 文章详情
  	  path: "/study/detail/:id",
  	  name: 'studyDetail',
  	  meta:{
  		  needLogin:false,//设置此路由是否需要登录状态
  		  title: '米因研究-文章详情'
  	  },
  	  component: () => import("@/components/study/detail")
  },
  
  //address
  {// 门店及地址
	  path: "/address",
	  name: 'address',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '香港體檢門店地址'
	  },
	  component: () => import("@/components/address")
  }, 
  {// 门店及地址地图
	  path: "/address/map",
	  name: 'addressMap',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '香港體檢門店地址地图'
	  },
	  component: () => import("@/components/address/map")
  }, 
  
  //login
  {// 登录
	  path: "/login",
	  name: 'login',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '登錄'
	  },
	  component: () => import("@/components/login")
  }, 
  
  //regist
  {// 注册
	  path: "/regist",
	  name: 'regist',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '註冊'
	  },
	  component: () => import("@/components/regist")
  },
  {// 用户协议
	  path: "/regist/agreement",
	  name: 'registAgreement',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '用戶協議'
	  },
	  component: () => import("@/components/regist/agreement")
  },
  {// 完善信息
	  path: "/regist/perfectInfo",
	  name: 'registPerfectInfo',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '完善信息'
	  },
	  component: () => import("@/components/regist/perfectInfo")
  },
  
  //forgetPwd
  {// 忘记密码
	  path: "/forgetPwd",
	  name: 'forgetPwd',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '忘記密碼'
	  },
	  component: () => import("@/components/forgetPwd")
  },
  
  //user
  {//个人中心
	  path: "/user",
	  name: 'user',
	  meta:{
		  needLogin:false,//设置此路由是否需要登录状态
		  title: '個人中心'
	  },
	  component: () => import("@/components/user")
  },
  {//个人信息
	  path: "/user/information",
	  name: 'userInformation',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '個人信息'
	  },
	  component: () => import("@/components/user/information")
  },
  {//實名認證
	  path: "/user/authentication",
	  name: 'userAuthentication',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '實名認證'
	  },
	  component: () => import("@/components/user/authentication/index")
  },
  {//實名認證用戶協議
	  path: "/user/authentication/agreement",
	  name: 'userAuthenticationAgreement',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '實名認證-用戶協議'
	  },
	  component: () => import("@/components/user/authentication/agreement")
  },
  {//修改密码
	  path: "/user/changePwd",
	  name: 'userChangePwd',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '修改密碼'
	  },
	  component: () => import("@/components/user/changePwd")
  },
  {//操作指引
	  path: "/user/guide",
	  name: 'userGuide',
	  meta:{
			needLogin:false,  //设置此路由是否需要登录状态
			title: '操作指引'
	  },
	  component: () => import("@/components/user/guide")
  },
  {//历史记录
	  path: "/user/history",
	  name: 'userHistory',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '歷史記錄'
	  },
	  component: () => import("@/components/user/history")
  },
  {//收据列表
	  path: "/user/invoice",
	  name: 'userInvoice',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '收据列表'
	  },
	  component: () => import("@/components/user/invoice/index")
  },
  {//收據詳情
	  path: "/user/invoice/collection/:id",
	  name: 'collection',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '收據詳情'
	  },
	  component: () => import("@/components/user/invoice/collection")
  },
  {//开具收据
	  path: "/user/invoice/applyInvoice/:id",
	  name: 'userApplyInvoice',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '開具收據'
	  },
	  component: () => import("@/components/user/invoice/applyInvoice")
  },
  {//留言反饋
	  path: "/user/feedback",
	  name: 'userFeedback',
	  meta:{
			needLogin:false,  //设置此路由是否需要登录状态
			title: '留言反饋'
	  },
	  component: () => import("@/components/user/feedback")
  },
  
  //question
  {
    path: "/question",
    name: "question",
	meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '問卷調查'
	},
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/question/question")
  },
  //editQuestion
  {
    path: "/editQuestion",
    name: "editQuestion",
	meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '修改信息'
	},
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/question/editQuestion")
  },
  //payment
  {
    path: "/payment/:id",
    name: "payment",
	meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '支付界面'
	},
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/payment/payment")
  },
  {//微信支付-香港
    path: "/wxPayHK",
    name: "wxPayHK",
  	meta:{
  			needLogin:true,  //设置此路由是否需要登录状态
  			title: '微信支付'
  	},
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/payment/wxPayHK")
  },

  //result
  {
	  path: "/result/:id",
	  name: 'result',
	  meta:{
			needLogin:true,  //设置此路由是否需要登录状态
			title: '評估結果'
	  },
	  component: () => import("@/components/result")
  },
  //米因學術中心
  {
  	  path: "/learn",
  	  name: 'learn',
  	  meta:{
  			needLogin:false,  //设置此路由是否需要登录状态
  			title: '米因學術中心'
  	  },
  	  component: () => import("@/components/learn")
  },
  //result
  {
  	  path: "/newgoods/mirrorReport/:id",
  	  name: 'mirrorReport',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '評估結果'
  	  },
  	  component: () => import("@/components/newgoods/mirrorReport")
  },
  //result
  {
  	  path: "/newgoods/creamReport/:id",
  	  name: 'creamReport',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '評估結果'
  	  },
  	  component: () => import("@/components/newgoods/creamReport")
  },
  //result
  {
  	  path: "/newgoods/result/:id",
  	  name: 'newResult',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '評估結果'
  	  },
  	  component: () => import("@/components/newgoods/result")
  },
  //newQuestion
  {
  	  path: "/newgoods/question",
  	  name: 'newQuestion',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '問卷調查'
  	  },
  	  component: () => import("@/components/newgoods/question")
  },
  //newEditQuestion
  {
  	  path: "/newgoods/editQuestion/:id",
  	  name: 'newEditQuestion',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '修改問卷'
  	  },
  	  component: () => import("@/components/newgoods/editQuestion")
  },
  //疫苗卡
  {
  	  path: "/vaccines",
  	  name: 'vaccines',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '米因智慧健康疫苗服務'
  	  },
  	  component: () => import("@/components/vaccines/index")
  },
  //疫苗卡 订单记录
  {
  	  path: "/vaccines/order",
  	  name: 'vaccinesOrder',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '訂單記錄'
  	  },
  	  component: () => import("@/components/vaccines/order")
  },
  //疫苗卡 預約記錄
  {
  	  path: "/vaccines/appoint",
  	  name: 'vaccinesQppoint',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '預約記錄'
  	  },
  	  component: () => import("@/components/vaccines/appoint")
  },
  //疫苗卡 接種記錄
  {
  	  path: "/vaccines/record",
  	  name: 'vaccinesRecord',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '接種記錄'
  	  },
  	  component: () => import("@/components/vaccines/record")
  },
  //疫苗卡 商品詳情
  {
  	  path: "/vaccines/goods_vac",
  	  name: 'vaccinesGoodsVac',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '商品詳情'
  	  },
  	  component: () => import("@/components/vaccines/goods_vac")
  },
  //疫苗卡 商品詳情
  {
  	  path: "/vaccines/goods_hpv",
  	  name: 'vaccinesGoodsHpv',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '商品詳情'
  	  },
  	  component: () => import("@/components/vaccines/goods_hpv")
  },
  //疫苗卡 疫苗卡密码
  {
  	  path: "/vaccines/card",
  	  name: 'vaccinesCard',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '米因大數據疫苗卡'
  	  },
  	  component: () => import("@/components/vaccines/card")
  },
  //疫苗卡 疫苗卡密码
  {
  	  path: "/vaccines/ask_vac",
  	  name: 'vaccinesAskvac',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '預約信息'
  	  },
  	  component: () => import("@/components/vaccines/ask_vac")
  },
  //疫苗卡 疫苗卡密码
  {
  	  path: "/vaccines/ask_hpv",
  	  name: 'vaccinesAskhpv',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '預約信息'
  	  },
  	  component: () => import("@/components/vaccines/ask_hpv")
  },
  //疫苗卡 登录
  {
  	  path: "/vaccines/login",
  	  name: 'vaccinesLogin',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '登錄'
  	  },
  	  component: () => import("@/components/vaccines/login")
  },
  //疫苗卡 登录
  {
  	  path: "/vaccines/clause_vac",
  	  name: 'vaccinesClausevac',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '預約條款'
  	  },
  	  component: () => import("@/components/vaccines/clause_vac")
  },
  //疫苗卡 登录
  {
  	  path: "/vaccines/clause_hpv",
  	  name: 'vaccinesClausehpv',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '預約條款'
  	  },
  	  component: () => import("@/components/vaccines/clause_hpv")
  },
  // 288 套餐 
  {
  	  path: "/goods/mirror",
  	  name: 'goodsMirror',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: 'AI健康套餐'
  	  },
  	  component: () => import("@/components/goods/mirror")
  },
  // case ai智慧健康 案例
  {
  	  path: "/case/index",
  	  name: 'caseSm',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '評估結果案例'
  	  },
  	  component: () => import("@/components/case/index")
  },
  // case ai智慧健康 案例
  {
  	  path: "/case/case_ai",
  	  name: 'caseAi',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '評估結果案例'
  	  },
  	  component: () => import("@/components/case/case_ai")
  },
  // case 白领精英 案例
  {
  	  path: "/case/case_cream",
  	  name: 'caseCream',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '評估結果案例'
  	  },
  	  component: () => import("@/components/case/case_cream")
  },
  // case 尊贵白金 案例
  {
  	  path: "/case/case_baijin",
  	  name: 'caseBaijin',
  	  meta:{
  			needLogin: false,  //设置此路由是否需要登录状态
  			title: '評估結果案例'
  	  },
  	  component: () => import("@/components/case/case_baijin")
  },
  {
  	  path: "/chatgpt",
  	  name: 'chatgpt',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '米因人工智能客服'
  	  },
  	  component: () => import("@/components/chatgpt/index")
  },
  {
  	  path: "/aizh",
  	  name: 'aizhHome',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '首頁'
  	  },
  	  component: () => import("@/components/aizh/home")
  },
  {
  	  path: "/aizh/user",
  	  name: 'aizhUser',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '個人中心'
  	  },
  	  component: () => import("@/components/aizh/user")
  },
  {
  	  path: "/aizh/question",
  	  name: 'aizhQuestion',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '問卷調查'
  	  },
  	  component: () => import("@/components/aizh/question")
  },
  {
  	  path: "/aizh/result/:id",
  	  name: 'aizhResult',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '問卷調查'
  	  },
  	  component: () => import("@/components/aizh/result")
  },
  {
  	  path: "/aizh/payment/:id",
  	  name: 'aizhPayment',
  	  meta:{
  			needLogin: true,  //设置此路由是否需要登录状态
  			title: '支付界面'
  	  },
  	  component: () => import("@/components/aizh/payment")
  },
  //overtime
  {
  	  path: "/overtime",
  	  name: "overtime",
  	  meta: {
  		  title: '超時'
  	  },
  	  component: () => import('@/components/overtime')
  },
  
  //404
  {
	  path: "/404",
	  name: "404",
	  meta: {
		  title: '404'
	  },
	  component: () => import('@/components/404')
  },
  {
	  path: "*",
	  redirect: "/404"
  }
];

const router = new VueRouter({
  routes: routes.concat(agent)
});

export default router;
