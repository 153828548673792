// 疫境求真代理人平台
const agent = [
	{// 首页
	  path: "/agent",
	  name: "agent",
		meta: {
			title: '首頁',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/home")
	},
	{// 登录
	  path: "/agent/login",
	  name: "agentLogin",
		meta: {
			title: '登录'
		},
	  component: () => import("@/components/agent/login")
	},
	{// 完善账号信息
	  path: "/agent/perfectInfo",
	  name: "agentPerfectInfo",
		meta: {
			title: '完善賬號信息',
			needLogin:false//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/perfectInfo")
	},
	{// 优惠码
	  path: "/agent/invitation",
	  name: "agentInvitation",
		meta: {
			title: '代理人邀請碼',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/invitation")
	},
	{// 礼品码
	  path: "/agent/invitation/giftCode",
	  name: "agentInvitationGiftCode",
		meta: {
			title: '禮品碼',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/invitation/giftCode")
	},
	{// 分享
	  path: "/agent/invitation/share",
	  name: "agentInvitationShare",
		meta: {
			title: '分享',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/invitation/share")
	},
	{// 疫境求真套餐购买
	  path: "/agent/packageBuy",
	  name: "agentPackageBuy",
		meta: {
			title: '疫境求真套餐購買',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/packageBuy")
	},
	
	// {// 疫境求真套餐开通
	//   path: "/agent/packageOpen",
	//   name: "agentpackageOpen",
	// 	meta: {
	// 		title: '疫境求真套餐開通',
	// 		needLogin:true//设置此路由是否需要登录状态
	// 	},
	//   component: () => import("@/components/agent/packageOpen")
	// },
	
	{// 收益報表
	  path: "/agent/earnings",
	  name: "agentEarnings",
		meta: {
			title: '收益報表',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/earnings")
	},
	{// 收益報表計算規則
	  path: "/agent/earnings/rules",
	  name: "agentEarningsRules",
		meta: {
			title: '收益報表計算規則',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/earnings/rules")
	},
	{// 收益明細
	  path: "/agent/earnings/detail",
	  name: "agentEarningsDetail",
		meta: {
			title: '收益明細',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/earnings/detail")
	},
	{// 提现账户信息
	  path: "/agent/earnings/withdraw",
	  name: "agentEarningsWithdraw",
		meta: {
			title: '提现账户信息',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/earnings/withdraw")
	},
	{// 修改密码
	  path: "/agent/changePwd",
	  name: "agentChangePwd",
		meta: {
			title: '修改密碼',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/changePwd")
	},
	
	{// 后台
	  path: "/agent/admin",
	  name: "agentAdmin",
		meta: {
			title: '疫境求真-大数据体检后台',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/admin")
	},
	{// 开通记录
	  path: "/agent/admin/openList",
	  name: "agentAdminOpenList",
		meta: {
			title: '开通记录',
			needLogin:true//设置此路由是否需要登录状态
		},
	  component: () => import("@/components/agent/admin/openList")
	},
	
];

export default agent;
