import axios from 'axios'  //这里引入axios  mian无需再引
import Vue from "vue";    //引入Vue
Vue.prototype.$ajax = axios;  //修改ajax原型指向为 axios
import Qs from 'qs';  // 引入Qs，这个库是axios里面包含的，不需要再下载了
import {Toast} from 'vant'  //vant-UI的提示组件
import { Base64 } from 'js-base64';

import store from '@/store'

// 统一请求路径前缀

// // const base = '/crdp';

// const base = '/ccma-server/api'   // 服务器地址/

const api = process.env.NODE_ENV == 'development'?'/api':(location.href.indexOf('m.medinbd') == -1?'https://apitest.medinbd.com':'https://api.medinbd.com');   // http//
// const token = '$2y$13$wt0puenSfedrFiv8ZUSlEeUnaWBgalSzosZAYHX0alBuD6U4Pe4Mq';  //token

// 超时设定
axios.defaults.timeout = 15000;

axios.interceptors.request.use(config => {
    return config
}, err => {
    Toast('请求超时');
    return Promise.resolve(err)
});

// http response 拦截器
axios.interceptors.response.use(response => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定)
	
	// token校验失败，重新登录
	if(data.status == -1 && data.msg.indexOf('请登录') != -1){
		if(/^#\/agent/.test(location.hash)){// 疫境求真代理人后台
			store.dispatch('agentExit').then(() => {
				location.reload();
			});
		}else{// 香港体检手机端
			store.dispatch('exit').then(() => {
				location.reload();
			});
		}
	}
	
    switch (data.code) {
        case 401:
            // 未登录 清除已登录状态
            Cookies.set('userInfo', '');
            setStore('accessToken', '');
            if (router.history.current.name !== 'login') {
                if (data.message !== null) {
                    Toast(data.message)
                } else {
                    Toast('未知错误，请重新登录')
                }
                router.push('/login')
            }
            break;
        case 403:
            // 没有权限
            if (data.message !== null) {
                Toast(data.message)
            } else {
                Toast('未知错误')
            }
            break;
        case 500:
            // 错误
            if (data.message !== null) {
                Toast(data.message)
            } else {
                Toast('未知错误')
            }
            break;
        default:
            return data;
    }
    return data
}, (err) => {
    // 返回状态码不为200时候的错误处理
    Toast(err.toString())
    return Promise.resolve(err)
});
//封装axios
 const axiosPost = (url, data, type, headers, contentType, processData,async) => {
	// 是否为疫境求真代理人后台
	var isAgent = /^#\/agent/.test(location.hash);
	if(isAgent){// 疫境求真代理人平台
		if(store.state.agentLogin){//已登录添加token
			const token = localStorage.getItem('agent_token')
		    Object.assign(data,{access_token: token});
		}
	}else{// 香港体检
		if(store.state.isLogin){//已登录添加token
			const token = localStorage.getItem('access_token')
		    Object.assign(data,{access_token: token});
		}
	}
    
    if(type == null || type == undefined){
        type = "GET"
    }
    if(headers == null || headers == undefined){
        headers = {}
    }
    if(contentType == null || contentType == undefined){
        contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
    if(processData == null || processData == undefined){
        processData = true
    }
    if(async == null || async == undefined){
        async = true
    }

    return axios({
        url:url,
        method:type,
        data:Qs.stringify(data),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'application/json',
            // 'accessToken': accessToken
        }
    })
};

// 新axios(token放入头部)
const axiosPost2 = (url, data, type, headers, contentType, processData,async) => {
	// 是否为疫境求真代理人后台
	var isAgent = /^#\/agent/.test(location.hash),
		token = '';
	if(isAgent){// 疫境求真代理人平台
		if(store.state.agentLogin){//已登录添加token
			token = localStorage.getItem('agent_token');
		}
	}else{// 健康100
		if(store.state.isLogin){//已登录添加token
			token = localStorage.getItem('access_token');
		}
	}
    
    if(type == null || type == undefined){
        type = "GET"
    }
    if(headers == null || headers == undefined){
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'application/json',
            // 'accessToken': accessToken
        }
    }
    if(contentType == null || contentType == undefined){
        contentType = "application/x-www-form-urlencoded; charset=UTF-8"
    }
    if(processData == null || processData == undefined){
        processData = true
    }
    if(async == null || async == undefined){
        async = true
    }

    return axios({
        url:url,
        method:type,
        data:Qs.stringify(data),
        headers: Object.assign({ 'x-token': token }, headers)
    })
};
//提交问卷调查信息
export const questionCommit = (id,data) => {
    return axiosPost(api + '/v1/analysis/health/'+id,data,'post');
};
//提交修改问卷调查信息
export const editQuestionCommit = (data) => {
    return axiosPost(api + '/v1/analysis/query/',{ data: window.btoa(JSON.stringify(data)) },'post');
};
//预约提交问卷调查信息
export const bookingCommit = (id,data) => {
    return axiosPost(api + '/v1/analysis/appoint/'+id,data,'post');
};

//結果查詢
export const resultCommit = (id, data) => {
	if(data == null || data == undefined){
		data = {};
	}
	return axiosPost(api + '/v1/analysis/detail/'+id,data,'post');
};

//获取圖形验证码
export const getImageCode = (data) => {
	return axiosPost(api + '/v1/user/getcaptcha/',data,'post');
};

//获取验证码 参数一：账号  参数二：验证码类型(1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码)
export const getVerifyCode = (data) => {
	return axiosPost(api + '/v1/user/sendsms/',{ data: window.btoa(JSON.stringify(data)) },'post');
};

//校验验证码 参数同获取验证码  少一个loa
export const verifyCode = (data) => {
	return axiosPost(api + '/v1/account/checkcode/',data,'post');
};

//註冊
export const regist = (data) => {
	return axiosPost(api + '/v1/user/register/',data,'post');
};

//登錄
export const login = (data) => {
	return axiosPost(api + '/v1/user/login/',{ data: window.btoa(JSON.stringify(data)) },'post');
};

//完善信息
export const profile = (data) => {
	return axiosPost(api + '/v1/user/profile/',{ data: encodeURIComponent(JSON.stringify(data)) },'post');
};

//token是否有效(需要登錄的頁面進入時會調取)
export const hasToken = () => {
	return axiosPost(api + '/v1/user/gettoken/',{},'post');
};

//获取套餐价格信息
export const packagePrice = () => {
	return axiosPost(api + '/v1/user/package/',{},'post');
};

//检查账号是否存在
export const accountExist = (data) => {
	return axiosPost(api + '/v1/account/exist/',data,'post');
};

//重置密碼
export const resetPwd = (data) => {
	return axiosPost(api + '/v1/account/resetpwd/',{ data: window.btoa(JSON.stringify(data)) },'post');
};

//修改密碼 
export const editPassWord = (data) => {
	return axiosPost(api + '/v1/account/modifypwd/',{ data: window.btoa(JSON.stringify(data)) },'post');
};

//历史记录 
export const pagingData = (data) => {
	return axiosPost(api + '/v1/analysis/list/',data,'post');
}

//申请开票 
export const invoiceList = (data) => {
	return axiosPost(api + '/v1/order/list/',data,'post');
}

//開具收據 
export const receipt = (data) => {
	return axiosPost(api + '/v1/analysis/receipt/',data,'post');
}

//留言反馈 
export const feedback = (data) => {
	return axiosPost(api + '/v1/user/feedback/',data,'post');
}

//支付界面
export const pay = (data) => {
	return axiosPost(api + '/v1/pay/order/',data,'post');
}

//是否支付
export const isPay = () => {
	return axiosPost(api + '/v1/user/tips/',{},'post');
}

//店鋪信息
export const addressList = () => {
	return axiosPost(api + '/v1/appoint/address/',{},'post');
}

//米因研究
//列表
export const studyList = (data) => {
	return axiosPost(api + '/v1/article/list/',data,'post');
}
//詳情
export const studyDetail = (id) => {
	return axiosPost(api + '/v1/article/detail/' + id,{},'post');
}

//微信支付
//香港
export const wxPayHK = (data) => {//微信支付
	// data.isMobile = 1;// 移动端标志
	return axiosPost2(api + '/v2/pay/hkwxpay/',{ data: window.btoa(JSON.stringify(data)) },'post');
}
//paypal支付
export const paypal = (data) => {
	return axiosPost2(api + '/v2/pay/paypal/',{ data: window.btoa(JSON.stringify(data)) },'post');
}

//微信分享
export const wxShare = (data) => {
	return axiosPost(api + '/v1/wx/wxshare/',data,'post');
}


// 疫境求真代理人平台
// 登录
export const agentLogin = (data) => {
	return axiosPost(api + '/v1/agent/login/',{ data: encodeURIComponent(JSON.stringify(data)) },'post');
}
// 修改账户密码(机构及kol)
export const perfectInfo = (data) => {
	return axiosPost(api + '/v1/agent/profile/',{ data: encodeURIComponent(JSON.stringify(data)) },'post');
}
// 获取被邀请码
export const agentGetInfo = (data) => {
	return axiosPost(api + '/v1/agent/getInfo/',data,'post');
}
// 用户信息
export const agentInfo = (data) => {
	return axiosPost(api + '/v1/agent/info/',(data?data:{}),'post');
}
// 套餐开通记录
export const openList = (data) => {
	return axiosPost(api + '/v1/agent/openList/',data,'post');
}
// 套餐开通
// export const comboOpen = (data) => {
// 	return axiosPost(api + '/v1/agent/open/',{ data: encodeURIComponent(JSON.stringify(data)) },'post');
// }
// 套餐购买
export const comboBuy = (data) => {
	return axiosPost(api + '/v1/agent/buy/',{ data: window.btoa(JSON.stringify(data)) },'post');
}
// 收益
export const profit = (data) => {
	return axiosPost(api + '/v1/agent/profit/',data,'post');
}
// 收益明细
export const profitDetail = (data) => {
	return axiosPost(api + '/v1/agent/profitDetail/',data,'post');
}
// 生成礼品码
export const genGiftCode = () => {
	return axiosPost(api + '/v1/agent/genGiftCode/',{},'post');
}
// 礼品码列表
export const giftCodeList = () => {
	return axiosPost(api + '/v1/agent/giftCodeList/',{},'post');
}
// 优惠码类型(优惠码或礼品码)
export const codeType = (data) => {
	return axiosPost2(api + '/v1/pay/ckCoupon/',{ data: encodeURIComponent(JSON.stringify(data)) },'post');
}
// 礼品码支付
export const giftCodePay = (data) => {
	return axiosPost(api + '/v1/pay/freePay/',{ data: window.btoa(JSON.stringify(data)) },'post');
}

// 后台
// 获取代理人人数信息
export const backAgentInfo = () => {
	return axiosPost(api + '/v1/backagent/info/',{},'post');
}
// 开通账号
export const backAgentOpen = (data) => {
	return axiosPost(api + '/v1/backagent/open/', { data: encodeURIComponent(JSON.stringify(data)) }, 'post');
}
// 开通记录
export const backAgentOpenList = (data) => {
	return axiosPost(api + '/v1/backagent/openList/',data,'post');
}



//问卷调查提交  参数：报告ID
export const newQuestionsSubmit = (data) => {
	return axiosPost(api + '/v1/analysis/appoint/', { data: window.btoa(JSON.stringify(data)) },'post');
}

//问卷调查提交  参数：报告ID
export const questionsSubmit = (data) => {
	return axiosPost2(api + '/v1/analysis/question/', { data: window.btoa(JSON.stringify(data)) },'post');
}

//留言  参数：报告ID
export const feedSubmit = (data) => {
	return axiosPost2(api + '/v1/analysis/comment/', { data: encodeURIComponent(JSON.stringify(data)) },'post');
}


//获取微信支付状态
export const getWxStatus = (data) => {
	return axiosPost2(api + '/v1/pay/query/',{ data: window.btoa(JSON.stringify(data)) },'post');
}

// Gpt2
export const getGpt2 = (data) =>{
	return axiosPost2(api + '/v1/user/qianfan/', { data: Base64.encode(JSON.stringify(data)) },'post');
}


// goodsList
export const getGoodsList = (data) =>{
	return axiosPost2(api + '/v1/goods/list/', data,'post');
}